.section-cta {
  padding-bottom: rem(120px);

  @include media-breakpoint-up(sm) {
    padding-bottom: rem(160px);
  }

  @include media-breakpoint-up(md) {
    padding-bottom: rem(190px);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: rem(230px);
  }

  .cta {
    display: grid;
    align-items: flex-end;
    gap: rem(40px);
    position: relative;
    padding: rem(110px) rem(36px) rem(36px);
    background: var(--color-gradient);
    border-radius: rem(15px);
    overflow: hidden;
    width: 100%;
    min-height: rem(350px);

    @include media-breakpoint-up(sm) {
      padding-top: rem(36px);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr rem(300px);
    }

    @include media-breakpoint-up(lg) {
      @include make-spacer(mx, auto);

      max-width: rem(1046px);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem(1200px);
    }

    &__text {
      color: var(--color-white);
      font-size: rem(40px);
      line-height: 1.1;
      font-weight: var(--typography-bold);

      @include media-breakpoint-up(sm) {
        max-width: 70%;
        font-size: rem(50px);
      }

      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }

    &__button {
      position: relative;
      z-index: 1;
      transition: var(--default-transition);
      width: 100%;
      max-width: rem(300px);
    }

    &__decor {
      @include size(rem(132px), auto);

      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-up(sm) {
        width: rem(224px);
      }

      @include media-breakpoint-up(md) {
        width: rem(320px);
      }
    }
  }
}
