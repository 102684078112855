.accordion {
  width: 100%;

  &__item {
    @include make-spacer(mb, 2);

    &--active {

      .accordion {

        &__arrow {
          transform: rotateZ(180deg)
        }

        &__body {
          max-height: rem(300px);
        }
      }
    }
  }

  &__arrow {
    @include flex(center, center);
    @include transition(all);
    @include size(rem(24px));

    position: absolute;
    top: rem(22px);
    right: 0;
  }

  &__trigger {
    @include flex($align-items: center);
    @include make-spacer(py, 3);
    @include make-spacer(pl, 5);
    @include make-spacer(pr, 8);

    position: relative;
    border-left: rem(7px) solid var(--color-pink);
    width: 100%;
    min-height: rem(65px);
    text-align: left;
  }

  &__title {
    line-height: 1;
    font-size: rem(20px);
    font-weight: var(--typography-medium);
  }

  &__body {
    @include make-spacer(pl, 7);
    @include make-spacer(py, half);

    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 400ms;
  }

  &__divider {
    @include make-spacer(my, 3);

    border: 0;
    background-color: var(--color-black);
    height: rem(1px);
  }
}
