.toggle {
  @include size(rem(30px));
  @include flex(center, center, column);

  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 12;

  &.active {

    .toggle {

      &__item {

        &:nth-child(1) {
          transform: translateY(rem(9px)) rotateZ(45deg);
        }

        &:nth-child(2) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:nth-child(3) {
          width: 100%;
          transform: translateY(rem(-9px)) rotateZ(-45deg);
        }
      }
    }
  }

  &__item {
    @include size(100%, rem(3px));

    display: block;
    transition: var(--default-transition);
    background-color: var(--color-white);
    margin-top: rem(3px);
    margin-bottom: rem(3px);

    &:last-child {
      @include make-spacer('ml', 0);
      @include make-spacer('mr', auto);

      width: rem(14px);
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
