.nav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: var(--color-black);

  .toggle,
  .sign-in {
    flex-shrink: 0;
  }

  .container {
    @include flex(space-between, center);

    height: rem(78px);

    @include media-breakpoint-up(sm) {
      height: rem(110px);
    }
  }

  .menu {
    margin-left: auto;
    margin-right: rem(50px);

    @include media-breakpoint-up(lg) {
      margin-right: rem(100px);
    }
  }

  &.is-transparent {
    background-color: transparent;
  }
}
