.section-recommendations {
  padding-bottom: rem(120px);
  background-color: var(--color-black);
  color: var(--color-white);

  @include media-breakpoint-up(sm) {
    padding-bottom: rem(160px);
  }

  @include media-breakpoint-up(md) {
    padding-bottom: rem(190px);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: rem(230px);
  }

  .reccomendations {

    &__button {
      @include make-spacer(mx, auto);

      padding: 0 rem(25px);
      margin-top: rem(100px);
    }

    &__item {
      display: grid;
      align-items: flex-start;
      grid-row-gap: rem(32px);
      margin-bottom: rem(60px);

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(sm) {
        grid-template-columns: rem(430px) 1fr;
        grid-column-gap: rem(100px);
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: rem(430px) rem(727px);
        justify-content: space-between
      }
    }

    &__quotes {
      position: relative;
      padding: rem(8px) 0 rem(8px) rem(32px);
      border-left: rem(7px) solid var(--color-pink);
    }

    &__subtitle {
      margin-bottom: rem(10px);
      line-height: 1.5;
      font-size: rem(20px);
    }
  }
}
