.logo {
  @include make-spacer(ml, 5);
  @include make-spacer(mr, auto);
  @include make-spacer(ml, auto, sm);
  @include make-spacer(ml, 0, md);
  @include size(rem(130px), rem(34px));

  @include media-breakpoint-up(sm) {
    @include size(rem(208px), rem(56px));

    transform: translateX(rem(67px));
  }

  @include media-breakpoint-up(md) {
    transform: translateX(0);
  }

  &__img {
    transform: translateY(rem(3px));
  }
}
