.footer {
  position: relative;
  overflow: hidden;
  background-color: var(--color-black);
  color: var(--color-white);

  &__content {
    position: relative;
    padding: rem(86px) 0 rem(160px);

    @include media-breakpoint-up(md) {
      padding-bottom: rem(256px);
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: rem(290px);
    }
  }

  &__logo {
    @include size(100%, auto);

    display: block;
    margin-bottom: rem(25px);
    max-width: rem(305px);

    @include media-breakpoint-up(sm) {
      margin-bottom: rem(50px);
      max-width: rem(222px);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: rem(77px);
      max-width: rem(300px);
    }

    @include media-breakpoint-up(lg) {
      max-width: rem(390px);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem(350px);
    }
  }

  &__decor {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: rem(49px);

    @include media-breakpoint-up(sm) {
      height: rem(101px);
    }

    @include media-breakpoint-up(md) {
      height: rem(135px);
    }

    @include media-breakpoint-up(lg) {
      height: rem(189px);
    }

    @include media-breakpoint-up(xl) {
      height: rem(252px);
    }
  }

  &__social {
    @include flex;

    @include media-breakpoint-up(sm) {
      margin-left: rem(305px);
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: rem(30px);
      right: 0;
      bottom: 0;
      margin-left: 0;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__wrapper {
    margin-bottom: rem(90px);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: rem(50px);

    @include media-breakpoint-up(sm) {
      grid-template-columns: rem(222px) repeat(2, 170px);
      grid-gap: rem(80px);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: rem(300px) repeat(2, 170px);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: rem(390px) repeat(2, 170px);
      grid-gap: rem(150px);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: rem(350px) repeat(2, 200px);
      grid-gap: rem(190px);
    }
  }

  &__list {
    font-size: rem(20px);
  }

  &__item {
    margin-bottom: rem(16px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-weight: var(--typography-extra-bold);
  }

  &__link {
    opacity: 0.6;
    transition: var(--default-transition);
    color: var(--color-white);
    font-weight: var(--typography-medium);

    &.active,
    &:hover {
      color: var(--color-pink);
      opacity: 1;
    }
  }
}
