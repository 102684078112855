.section-description {

  .description {
    position: relative;
    overflow: hidden;
    border: rem(1px) solid var(--color-pale-slate);
    border-radius: rem(24px);
    padding: rem(38px) rem(24px);

    @include media-breakpoint-up(sm) {
      padding: rem(50px);
    }

    @include media-breakpoint-up(md) {
      padding: rem(50px) rem(67px);
    }

    @include media-breakpoint-up(lg) {
      padding: rem(60px) rem(100px);
    }

    &__decor {
      position: absolute;
      top: 0;
      right: 0;
      width: rem(100px);

      @include media-breakpoint-up(sm) {
        width: rem(150px);
      }

      @include media-breakpoint-up(md) {
        width: rem(236px);
      }
    }

    &__grid {
      display: grid;
      grid-row-gap: rem(32px);

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: rem(216px);
        grid-row-gap: rem(130px);
      }
    }

    &__item {
      padding-bottom: rem(40px);
      position: relative;
      border-bottom: rem(1px) solid var(--color-pale-slate);

      &:last-child {
        border-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        border-bottom: 0;
      }

      &::after,
      &::before {
        display: none;
        position: absolute;
        background-color: var(--color-pale-slate);
        content: '';

        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {

        &::after {
          @include size(100%, rem(1px));

          top: rem(-70px);
          left: 0;
        }
      }


      &:nth-child(2),
      &:nth-child(4){

        &::before {
          @include size(rem(1px), 100%);

          top: 0;
          left: rem(-106px);
        }
      }
    }

    &__headline {
      position: relative;
      padding-right: rem(40px);

      @include media-breakpoint-up(md) {
        padding-right: rem(75px);
      }
    }

    &__title {
      @include make-spacer(mb, 4);
      @include make-spacer(mt, 3);

      max-width: rem(252px);
      font-size: rem(24px);
      font-weight: var(--typography-bold);
      line-height: 1.1;
      letter-spacing: rem(-1px);

      @include media-breakpoint-up(md) {
        font-size: rem(30px);
        line-height: 1.3;
      }
    }

    &__text {
      @include make-spacer(mb, 3);
    }

    &__list {
      @include make-spacer(mt, 3);
      @include make-spacer(mb, 5);
    }
  }
}
