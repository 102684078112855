.header {
  overflow: hidden;
  background-color: var(--color-black);
  color: var(--color-white);

  .container {
    display: grid;
    align-items: center;
    gap: rem(40px);
    position: relative;
    padding-top: rem(150px);
    padding-bottom: rem(70px);
    min-height: 100dvh;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: rem(50px);
    }

    @include media-breakpoint-up(xl) {
      padding-right: var(--gutter);
      padding-bottom: rem(176px);
    }
  }

  .headline {
    @include font-size(rem(40px), rem(45px));

    @include media-breakpoint-up(sm) {
      @include font-size(rem(60px), rem(70px));
    }

    @include media-breakpoint-up(md) {
      @include font-size(rem(70px), rem(75px));
    }
  }

  .description {
    display: grid;
    gap: rem(40px);
    max-width: rem(900px);

    &__text {
      font-size: rem(18px);

      @include media-breakpoint-up(sm) {
        @include font-size(rem(20px), rem(30px));
      }

      @include media-breakpoint-up(lg) {
        max-width: rem(500px);
        font-size: rem(18px);
      }
    }

    &__button {
      margin-bottom: rem(20px);
      width: 100%;

      @include media-breakpoint-up(sm) {
        max-width: rem(400px);
      }
    }
  }

  .social {
    justify-content: flex-start;
    z-index: 1;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: rem(235px);
      right: var(--gutter);
    }

    @include media-breakpoint-up(lg) {
      right: rem(-50px);
    }
  }
}
