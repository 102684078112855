.list {

  &__item {
    @include make-spacer(mb, 1);

    position: relative;
    padding-left: rem(14px);
    line-height: 1.3;
    font-size: rem(15px);

    &::before {
      @include size(rem(4px));

      position: absolute;
      top: rem(8px);
      left: 0;
      border-radius: 50%;
      background-color: currentColor;
      content: ""
    }

    &:last-child {
      @include make-spacer(mb, 0);
    }
  }
}
