@mixin reset-button {
  margin: 0;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: auto;
  overflow: visible;
}
