.section-feedback {

  .container {
    position: relative;

    &::before {
      @include absolute-filled;

      transform: translateX(50%);
      background-color: var(--color-black);
      content: '';
    }
  }

  .feedback {
    position: relative;
    border-top-left-radius: rem(24px);
    border-bottom-left-radius: rem(24px);
    background-color: var(--color-black);
    padding: rem(60px) 0 rem(85px) rem(40px);
    color: var(--color-white);

    @include media-breakpoint-up(lg) {
      padding-left: rem(104px);
    }

    &__headline {
      display: grid;
      column-gap: rem(24px);
      margin-bottom: rem(90px);
      max-width: rem(575px);

      @include media-breakpoint-up(lg) {
        grid-template-columns: rem(575px) rem(274px);
        align-items: baseline;
        justify-content: space-between;
        max-width: 100%;
      }
    }

    &__description {
      font-size: rem(18px);
    }

    &__list {
      display: grid;
      row-gap: rem(44px);

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, rem(275px));
        column-gap: rem(24px);
        justify-content: space-between;
      }
    }

    &__item {
      @include flex($direction: column);

      gap: rem(24px);

      &--pink {

        span,
        path {
          color: var(--color-pink);
        }
      }

      &--blue {

        span,
        path {
          color: var(--color-blue);
        }
      }

      &--azure {

        span,
        path {
          color: var(--color-azure);
        }
      }
    }

    &__subtitle {
      line-height: 1.16;
      font-size: rem(30px);
    }
  }
}
