.sign-in {
  width: rem(110px);
  font-size: rem(16px);
  transition: var(--default-transition);
  white-space: nowrap;

  &:hover {
    border-color: var(--color-pink);
    color: var(--color-pink);
  }

  @include media-breakpoint-up(sm) {
    width: rem(165px);
    font-size: rem(20px);
  }
}
