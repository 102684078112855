.section-clients {


  .banner {
    @include size(rem(380px));
    @include flex(center, center);

    position: relative;
    padding-left: rem(80px);
    margin-right: 0;
    margin-left: auto;

    @include media-breakpoint-up(sm) {
      @include size(rem(492px));

      padding-left: rem(20px);
    }

    &__decor {
      @include size(rem(380px));

      position: absolute;
      top: 0;
      left: -25%;
      object-fit: contain;

      @include media-breakpoint-up(sm) {
        @include size(rem(492px));

        left: -30%;
      }
    }

    &__wrapper {
      @include flex($direction: column);

      position: relative;
      z-index: 1;
      gap: rem(15px);
      max-width: rem(320px);
      width: 100%;
    }

    &__count {
      font-size: rem(90px);
      font-weight: var(--typography-extra-bold);
      line-height: 1;
      letter-spacing: rem(-5px);

      @include media-breakpoint-up(sm) {
        font-size: rem(120px);
      }
    }

    &__subtitle {
      font-size: rem(18px);
      font-weight: var(--typography-bold);
      line-height: 1.5;

      @include media-breakpoint-up(sm) {
        font-size: rem(20px);
        line-height: 1.3;
      }
    }
  }

  .clients {
    position: relative;

    @include media-breakpoint-up(sm) {
      @include make-spacer(mx, auto);

      max-width:45.1875rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }

    &__headline {
      @include flex($direction: column-reverse);

      gap: rem(50px);

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &__slider {
      position: relative;
      padding-bottom: rem(85px);
    }
  }

  .client {

    &__title {
      margin-bottom: rem(30px);
      line-height: 1.2;
      font-size: rem(20px);
      font-weight: var(--typography-bold);
    }

    &__footer {
      @include flex($align-items: center);

      margin-top: rem(40px);
      gap: rem(16px);
    }

    &__button {
      @include make-spacer(px, 2);
      @include make-spacer(py, 1);

      width: fit-content;
      border: rem(1px) solid var(--color-black);
      line-height: 1.2;
      font-weight: var(--typography-bold);
      font-size: rem(12px);

      &--fill {
        background-color: var(--color-black);
        color: var(--color-white);
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include size(rem(14px), rem(27px));

    color: var(--color-purple);

    &::after {
      font-size: rem(24px);
    }
  }

  .swiper-button-prev {
    left: rem(-50px);
  }

  .swiper-button-next {
    right: rem(-50px);
  }

  .swiper-pagination {

    .swiper-pagination-bullet {
      @include size(rem(12px));

      margin: 0 rem(12px);

      &.swiper-pagination-bullet-active {
        background-color: var(--color-purple);
      }
    }
  }
}
