.menu {
  @include make-spacer('m', 0);
  @include make-spacer('p', 0);

  overflow: hidden;

  &__item {
    color: var(--color-white);

    &.active,
    &:hover {
      color: var(--color-pink);
    }
  }

  &__text {
    font-size: rem(30px);
    font-weight: var(--typography-medium);
  }

  &--default {
    display: none;

    @include media-breakpoint-up(md) {
      @include flex(center, center);
    }

    .menu {

      &__item {
        margin-right: rem(50px);

        @include media-breakpoint-up(lg) {
          margin-right: rem(70px);
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__text {
        font-size: rem(20px);
      }
    }
  }

  &--column {
    @include flex(center, center, column);

    .menu {

      &__item {
        margin-bottom: rem(40px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
