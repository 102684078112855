:root {
  --color-white: #fff;
  --color-black: #000;
  --color-pink: #ce64c7;
  --color-purple: #674bda;
  --color-azure: #61D5D1;
  --color-blue: #674BDA;
  --color-pale-slate: #cdcdcd;
  --color-boulder: #767676;
  --color-gradient: radial-gradient(155.45% 90% at 102.5% 24.78%, #B650D3 0%, #8050EE 69.31%, #2BADDA 100%);
}
