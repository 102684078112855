*,
::after,
::before {
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}

body {
  @include smooth-font;

  min-width: var(--min-width);
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(---color-white);
  color: var(--color-black);
  line-height: var(--line-height);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--typography-regular);

  &.overflow {
    overflow: hidden;
  }
}

button,
a {
  transition: color 0.3s ease-in-out;
}

button {
  @include reset-button;
}

a {
  cursor: pointer;
  color: var(--color-black);
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

p {
  @include make-spacer(m, 0);
  @include make-spacer(p, 0);
}

section {
  padding-top: rem(120px);

  @include media-breakpoint-up(sm) {
    padding-top: rem(160px);
  }

  @include media-breakpoint-up(md) {
    padding-top: rem(200px);
  }

  @include media-breakpoint-up(xl) {
    padding-top: rem(250px);
  }
}

.link {
  color: var(--color-purple);
}

.picture,
.svg,
.img {
  @include size(100%);

  display: block;
  object-fit: contain;
  object-position: center;
}

.img {

  &--cover {
    object-fit: cover;
  }
}

.main {
  position: relative;
  overflow: hidden;
}

.container {
  @include make-spacer('mx', auto);

  padding-left: var(--gutter);
  padding-right: var(--gutter);
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: rem(1265px);
  }

  @include media-breakpoint-up(xl) {
    max-width: rem(1588px);
  }
}

.default-animation {
  transition-duration: .8s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  opacity: 0;
  transform: translateY(1rem);

  &.active {
    opacity: 1;
    transform: translateY(0)
  }
}

.title {
  @include make-spacer(mb, 10);

  letter-spacing: rem(-2px);
  line-height: 1.15;
  word-break: break-word;
  font-size: rem(40px);

  @include media-breakpoint-up(sm) {
    font-size: rem(60px);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(75px);
  }

  &__icon {
    @include size(rem(26px));

    display: inline-block;
    vertical-align: baseline;
    margin-left: rem(18px);

    @include media-breakpoint-up(sm) {
      @include size(rem(40px));

      margin-left: rem(28px);
    }

    @include media-breakpoint-up(md) {
      @include size(rem(48px));
    }

    svg {
      display: block;
    }
  }

  &--inset {

    .title {

      &__icon {
        margin-bottom: rem(-5px);
      }
    }
  }
}
