.section-faq {

  .faq {

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr rem(620px);
      column-gap: rem(24px);
    }

    &__text {
      font-size: rem(20px);
      line-height: 1.5;
    }

    &__accordion {
      @include make-spacer(mb, 8);
    }
  }
}
