.section-steps {
  padding-bottom: rem(120px);

  @include media-breakpoint-up(sm) {
    padding-bottom: rem(160px);
  }

  @include media-breakpoint-up(md) {
    padding-bottom: rem(190px);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: rem(230px);
  }

  .steps {

    &__tab {
      display: none;
      border-bottom: rem(1px) solid var(--color-pale-slate);

      @include media-breakpoint-up(md) {
        @include flex(space-between, center);
      }
    }

    &__link {
      @include flex(center, center);

      position: relative;
      flex-shrink: 0;
      font-size: rem(18px);
      padding: 0 rem(30px);
      height: rem(60px);
      color: var(--color-boulder);
      font-weight: var(--typography-bold);
      text-align: center;

      &:hover,
      &--active {
        color: var(--color-purple);
      }

      &--active {
        color: var(--color-purple);

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--color-purple);
          height: rem(4px);
          content: '';
        }
      }

      &--alt {
        @include size(100%, rem(75px));

        border-bottom: rem(1px) solid var(--color-pale-slate);

        &::before {
          display: none;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &__body {
      display: none;
      padding-top: rem(40px);
      padding-bottom: rem(30px);

      @include media-breakpoint-up(md) {
        padding-top: rem(50px);
        padding-bottom: 0;
      }

      &--active {
        display: block;
      }
    }

    &__image {
      max-width: 100%;
    }

    &__text {
      @include make-spacer(mx, auto);

      margin-bottom: rem(48px);
      max-width: rem(640px);
      line-height: 1.5;
      text-align: center;
      font-weight: var(--typography-bold);
    }

    &__url {
      @include make-spacer(mx, 1);

      display: inline;
      color: var(--color-purple);
    }
  }
}
