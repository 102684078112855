.button {
  @include flex(center, center);
  @include reset-button;

  position: relative;
  width: fit-content;
  font-size: rem(20px);
  font-weight: var(--typography-bold);
  text-align: center;
  color: var(--color-white);

  &--sm {
    padding: rem(14px) rem(25px);
    height: rem(45px);
  }

  &--md {
    padding: rem(15px) rem(48px);
    height: rem(58px);
  }

  &--lg {
    height: rem(80px);
    font-size: rem(30px);
  }

  &--outline {
    border: 1px solid var(--color-white);
  }

  &--white {
    background-color: var(--color-white);
    color: var(--color-black);

    &:hover {
      color: var(--color-pink);
    }
  }

  &--black {
    background-color: var(--color-black);

    &:hover {
      color: var(--color-pink);
    }
  }
}
