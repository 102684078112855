.aside {
  @include flex(center, center, column);

  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  background-color: var(--color-black);
  transform: translateX(-100%);
  transition: var(--default-transition);

  @include media-breakpoint-up(sm) {
    padding-top: rem(110px);
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  .menu {
    margin-bottom: rem(60px);

    @media screen and ( max-height: rem(400px) ) {
      margin-bottom: rem(20px);
    }

    @include media-breakpoint-up(sm) {
      transform: translateY(rem(-30px));
    }
  }

  .social {
    position: absolute;
    bottom: rem(26px);
    left: var(--gutter);

    @include media-breakpoint-up(sm) {
      bottom: rem(42px);
    }
  }

  &.active {
    transform: translateX(0);
  }
}
